import axios from "axios";
import { baseUrl } from "../components/registeration/constants";
export const RegisterInformation = async (values) => {
  values.reference = values?.reference?.toString() || "N/A";
  values.propertySearchingTime = values?.extras?.toString() || "N/A";
  values.propertySearchingTime =
    values?.propertySearchingTime?.toString() || "N/A";
  const response = await axios
    .post(`${baseUrl}/sendMail`, values)
    .then((response) => console.log("response", response))
    .catch((error) => {
      console.error("There was an error!", error);
    });
  return response;
};
export const getPlacesFromApi = async (searchQuery) => {
  let data = [];
  await axios
    .get(`${baseUrl}/places?place=${searchQuery}`)
    .then((response) => {
      data = response;
    })
    .catch((error) => {
      console.error("There was an error!", error);
    });

  return data;
};
