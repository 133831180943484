import React from "react";
import Footer from "../footer";
import Registeration from "../registeration";
import "./styles.css";

const HomePage = () => {
  return (
    <div className="home-page-background">
      <Registeration />
      <Footer />
    </div>
  );
};

export default HomePage;
