export const LocationSvg = () => {
  return (
    <svg
      width="15"
      height="21"
      viewBox="0 0 15 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.5 0C3.35357 0 0 3.2865 0 7.35C0 12.8625 7.5 21 7.5 21C7.5 21 15 12.8625 15 7.35C15 3.2865 11.6464 0 7.5 0ZM7.5 9.975C6.02143 9.975 4.82143 8.799 4.82143 7.35C4.82143 5.901 6.02143 4.725 7.5 4.725C8.97857 4.725 10.1786 5.901 10.1786 7.35C10.1786 8.799 8.97857 9.975 7.5 9.975Z"
        fill="black"
      />
    </svg>
  );
};
export const RightArrow = () => {
  return (
    <svg
      width="23"
      height="8"
      viewBox="0 0 23 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M17.2644 3H0V5H17.2644V8L23 4L17.2644 0V3Z" fill="white" />
    </svg>
  );
};
