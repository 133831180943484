import React, { useState, useMemo } from "react";
//library
import { Formik, Field, Form, useFormikContext } from "formik";
import { Select, Menu } from "antd";
import { Input } from "antd";
import { Button, Modal, Checkbox, notification, Spin } from "antd";
//src
import { getPlacesFromApi, RegisterInformation } from "../../service";
//assets
import Logo from "../../assets/images/logo.png";
import Background from "../../assets/images/background.png";
import { LocationSvg, RightArrow } from "../../assets/images/svg";
//styles
import "./styles.css";
import "antd/dist/antd.css";
import {
  Accomodation_Types,
  Area_Range,
  Gewerbe,
  Grundstück_Types,
  Haus_Types,
  Price_Range,
  Property_Types,
  Rooms_Range,
  Wohnung_Types,
  Reference,
  GrundDerNutzung_Types,
  Extra_Options,
  propertySearchingTime,
  IchWohneBisherList,
  Property_Options,
  Anbieten_Types,
  Anbieten_Options,
  Umkreis_Types,
  AusstattungList,
  AusschlusskriterienList,
  Haustiere_Types,
  Wohnberechtigungsschein_Types,
  Baujahr_Types,
  WohnungstypList,
} from "./constants";
import { locationSvg } from "../../assets/images/svg";

const { Option } = Select;

const Registeration = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSubmittingData, setSubmittingData] = useState(false);
  const [placesLoading, setPlacesLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [places, setPlaces] = useState([]);
  const [showMenu, setMenu] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };

  React.useEffect(() => {
    const getPlaces = async () => {
      if (!searchQuery?.length) {
        setPlaces([]);
        return;
      }
      let places = [];
      try {
        setPlacesLoading(true);
        setMenu(true);
        await getPlacesFromApi(searchQuery).then((data) => {
          if (data?.data?.results?.length) {
            places = data?.data?.results?.reduce(
              (total, current) => {
                let currentItem = {
                  name: current?.formatted_address,
                  value: current?.formatted_address,
                };
                return [...total, currentItem];
              },
              [{ name: searchQuery, value: searchQuery }]
            );
          }
          setPlacesLoading(false);
        });
      } catch (e) {
        console.log(e);
        setPlacesLoading(false);
      }
      setPlaces(places);
      return places;
    };
    getPlaces();
  }, [searchQuery]);
  const options = useMemo(() => {
    return places;
  }, [places]);

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const openNotification = (text) => {
    notification.open({
      message: "",
      description: text,
      onClick: () => {
        handleCancel();
      },
    });
  };
  const sendMail = async (values) => {
    setSubmittingData(true);
    const response = await RegisterInformation(values);

    if (response?.data?.sent) {
      openNotification(
        "Vielen Dank für Ihr Interesse!\nSobald wir die passende Immobilie für Sie haben, werden Wir Uns mit Ihnen in Verbindung setzen."
      );
    } else {
      openNotification(
        "Vielen Dank für Ihr Interesse!\nSobald wir die passende Immobilie für Sie haben, werden Wir Uns mit Ihnen in Verbindung setzen."
      );
    }
    setSubmittingData(false);
    setIsModalOpen(false);
  };
  if (isSubmittingData) {
    return <Spin size="large" />;
  }
  return (
    <div className="registeration-container">
      <img src={Logo} alt="logo " />
      <div>
        <h3 className="headings">Sie suchen eine Immobilie?</h3>
        <p>Wir helfen Ihnen dabei, das Passende zu finden</p>
      </div>
      <Formik
        initialValues={{
          accomodationType: "Wohnung",
          address: "Please Type",
          rooms: "2 Zimmer",
          area: "ab 40 m²",
          price: "Bis 700€",
          propertyType: "Miete",
          wohnung: "Dachgeschoss",
        }}
        enableReinitialize
      >
        {({ setFieldValue, values }) => {
          return (
            <div className="registeration">
              <div className="registeration-form">
                <Form className="form">
                  <Modal
                    title="Suchauftrag einrichten"
                    open={isModalOpen}
                    onOk={handleOk}
                    onCancel={handleCancel}
                    className="modal"
                    mask
                    footer={
                      <div className="footer-button">
                        <Button
                          className="button"
                          type="primary"
                          onClick={() => {
                            sendMail(values);
                          }}
                        >
                          <p className="button-title">Absenden</p>
                        </Button>
                      </div>
                    }
                  >
                    <div className="section-filter">
                      <h2 className="center-heading"> Weitere Merkmale</h2>
                      <p>Haustiere</p>
                      <Select
                        style={{ width: "100%" }}
                        name="Haustiere"
                        placeholder="Select Haustiere"
                        value={values?.Haustiere}
                        onChange={(selected) => {
                          setFieldValue("Haustiere", selected);
                        }}
                      >
                        {Haustiere_Types?.map((Accomodation_Type) => (
                          <Option value={Accomodation_Type.key}>
                            <div className="option-with-vertical-divider">
                              <div className="type">
                                {Accomodation_Type.label}
                              </div>
                            </div>
                          </Option>
                        ))}
                      </Select>
                      <p>Wohnberechtigungsschein</p>
                      <Select
                        style={{ width: "100%" }}
                        name="Wohnberechtigungsschein"
                        placeholder="Select Wohnberechtigungsschein"
                        value={values?.Wohnberechtigungsschein}
                        onChange={(selected) => {
                          setFieldValue("Wohnberechtigungsschein", selected);
                        }}
                      >
                        {Wohnberechtigungsschein_Types?.map(
                          (Accomodation_Type) => (
                            <Option value={Accomodation_Type.key}>
                              <div className="option-with-vertical-divider">
                                <div className="type">
                                  {Accomodation_Type.label}
                                </div>
                              </div>
                            </Option>
                          )
                        )}
                      </Select>
                      <p>Baujahr</p>
                      <Select
                        style={{ width: "100%" }}
                        name="Baujahr"
                        placeholder="Select Baujahr"
                        value={values?.Baujahr}
                        onChange={(selected) => {
                          setFieldValue("Baujahr", selected);
                        }}
                      >
                        {Baujahr_Types?.map((Accomodation_Type) => (
                          <Option value={Accomodation_Type.key}>
                            <div className="option-with-vertical-divider">
                              <div className="type">
                                {Accomodation_Type.label}
                              </div>
                            </div>
                          </Option>
                        ))}
                      </Select>
                      <div className="reference">
                        <p>Wohnungstyp</p>
                        <Checkbox.Group
                          options={WohnungstypList}
                          defaultValue={[]}
                          name="Wohnungstyp"
                          onChange={(e) => {
                            setFieldValue("Wohnungstyp", e);
                          }}
                        />
                      </div>
                    </div>
                    {/* <div className="section-filter">
                      <p>Anbieten</p>
                      <Select
                        style={{ width: "100%" }}
                        name="Anbieten"
                        placeholder="Select Anbieten"
                        value={values?.Anbieten}
                        onChange={(selected) => {
                          setFieldValue("Anbieten", selected);
                        }}
                      >
                        {Anbieten_Types?.map((Accomodation_Type) => (
                          <Option value={Accomodation_Type.key}>
                            <div className="option-with-vertical-divider">
                              <div className="type">
                                {Accomodation_Type.label}
                              </div>
                            </div>
                          </Option>
                        ))}
                      </Select>
                      <p>Anbieten Value</p>
                      <Select
                        style={{ width: "100%" }}
                        name="AnbietenValue"
                        placeholder="Select Anbieten Value"
                        value={values?.AnbietenValue}
                        onChange={(selected) => {
                          setFieldValue("AnbietenValue", selected);
                        }}
                      >
                        {Anbieten_Options[values.Anbieten]?.map(
                          (Accomodation_Type) => (
                            <Option value={Accomodation_Type.key}>
                              <div className="option-with-vertical-divider">
                                <div className="type">
                                  {Accomodation_Type.label}
                                </div>
                              </div>
                            </Option>
                          )
                        )}
                      </Select>
                    </div> */}
                    <div>
                      <p>Umkreis</p>
                      <Select
                        style={{ width: "100%" }}
                        name="Umkreis"
                        placeholder="Select Umkreis"
                        value={values?.Umkreis}
                        onChange={(selected) => {
                          setFieldValue("Umkreis", selected);
                        }}
                      >
                        {Umkreis_Types?.map((Accomodation_Type) => (
                          <Option value={Accomodation_Type.key}>
                            <div className="option-with-vertical-divider">
                              <div className="type">
                                {Accomodation_Type.label}
                              </div>
                            </div>
                          </Option>
                        ))}
                      </Select>
                    </div>
                    <div></div>
                    <div>
                      <p>Grundstück</p>
                      <Select
                        style={{ width: "100%" }}
                        name="Grundstück"
                        placeholder="Select Grundstück"
                        onChange={(selected) => {
                          setFieldValue("Grundstück", selected);
                        }}
                      >
                        {Grundstück_Types?.map((Accomodation_Type) => (
                          <Option value={Accomodation_Type.type}>
                            <div className="option-with-vertical-divider">
                              <div className="type">
                                {Accomodation_Type.type}
                              </div>
                            </div>
                          </Option>
                        ))}
                      </Select>
                    </div>
                    <div>
                      <p>Gewerbe</p>
                      <Select
                        defaultValue="Büro"
                        style={{ width: "100%" }}
                        name="gewerbe"
                        onChange={(selected) => {
                          setFieldValue("gewerbe", selected);
                        }}
                      >
                        {Gewerbe?.map((Accomodation_Type) => (
                          <Option value={Accomodation_Type.key}>
                            {Accomodation_Type.label}
                          </Option>
                        ))}
                      </Select>
                    </div>
                    <div>
                      <p>Haus</p>
                      <Select
                        defaultValue="Einfamilienhaus"
                        style={{ width: "100%" }}
                        name="haus"
                        onChange={(selected) => {
                          setFieldValue("haus", selected);
                        }}
                      >
                        {Haus_Types?.map((Accomodation_Type) => (
                          <Option value={Accomodation_Type.key}>
                            {Accomodation_Type.label}
                          </Option>
                        ))}
                      </Select>
                    </div>
                    <div>
                      <p>Grund der Nutzung</p>
                      <Select
                        defaultValue="Kapitalanlage"
                        style={{ width: "100%" }}
                        name="grundDerNutzung"
                        onChange={(selected) => {
                          setFieldValue("grundDerNutzung", selected);
                        }}
                      >
                        {GrundDerNutzung_Types?.map((Accomodation_Type) => (
                          <Option value={Accomodation_Type.key}>
                            {Accomodation_Type.label}
                          </Option>
                        ))}
                      </Select>
                    </div>

                    <Checkbox
                      name="financingConfirmation"
                      onChange={(e) => {
                        setFieldValue(
                          "financingConfirmation",
                          e.target.checked
                        );
                      }}
                    >
                      Liegt bereits eine Finanzierungsbestätigung vor?
                    </Checkbox>
                    <Checkbox
                      name="financeAdviceRequired"
                      onChange={(e) => {
                        setFieldValue(
                          "financeAdviceRequired",
                          e.target.checked
                        );
                      }}
                    >
                      Wünschen Sie eine Finanzierungsberatung?
                    </Checkbox>

                    {/* <div className="label-and-input">
                      <p>Grundstücksfläche ca. in sqm</p>
                      <Input
                        style={{ width: "30%" }}
                        placeholder="Enter Grundstücksfläche ca. in sqm"
                        name="Grundstücksfläche"
                        onChange={(selected) => {
                          setFieldValue(
                            "Grundstücksfläche",
                            selected.target.value
                          );
                        }}
                      />
                    </div> */}
                    <div className="label-and-input">
                      <p>Kaufpreis bis</p>
                      <Input
                        style={{ width: "30%" }}
                        placeholder="Enter Kaufpreis bis"
                        name="Kaufpreisbis"
                        onChange={(selected) => {
                          setFieldValue("Kaufpreisbis", selected.target.value);
                        }}
                      />
                    </div>
                    <div className="label-and-input">
                      <p>Kaufpreis von</p>
                      <Input
                        style={{ width: "30%" }}
                        placeholder="Enter Kaufpreis von"
                        name="Kaufpreisvon"
                        onChange={(selected) => {
                          setFieldValue("Kaufpreisvon", selected.target.value);
                        }}
                      />
                    </div>

                    <div className="label-and-input">
                      <p>Meine monatliche Miete</p>
                      <Input
                        style={{ width: "30%" }}
                        placeholder="Type here"
                        name="MeineMonatlicheMiete"
                        onChange={(selected) => {
                          setFieldValue(
                            "MeineMonatlicheMiete",
                            selected.target.value
                          );
                        }}
                      />
                    </div>

                    <div className="label-and-input">
                      <p>
                        Für den Immobilienkauf kann ich monatlich investieren
                      </p>
                      <Input
                        style={{ width: "30%" }}
                        placeholder="Fürden Immobilienkauf kann ich monatlich investieren"
                        name="monatlichInvestieren"
                        onChange={(selected) => {
                          setFieldValue(
                            "monatlichInvestieren",
                            selected.target.value
                          );
                        }}
                      />
                    </div>

                    <div className="reference">
                      <p>Wie sind Sie auf uns aufmerksam geworden?</p>
                      <Checkbox.Group
                        options={Reference}
                        defaultValue={[]}
                        name="reference"
                        onChange={(e) => {
                          setFieldValue("reference", e);
                        }}
                      />
                    </div>
                    <div className="reference">
                      <p>Wie lange suchen Sie bereits eine Immobilie?</p>
                      <Checkbox.Group
                        options={propertySearchingTime}
                        defaultValue={[]}
                        name="propertySearchingTime"
                        onChange={(e) => {
                          setFieldValue("propertySearchingTime", e);
                        }}
                      />
                    </div>
                    <div className="reference">
                      <p>Ich wohne bisher</p>
                      <Checkbox.Group
                        options={IchWohneBisherList}
                        defaultValue={[]}
                        name="IchWohneBisher"
                        onChange={(e) => {
                          setFieldValue("IchWohneBisher", e);
                        }}
                      />
                    </div>
                    <div className="reference">
                      <p>Ausstattung</p>
                      <Checkbox.Group
                        options={AusstattungList}
                        defaultValue={[]}
                        name="Ausstattung"
                        onChange={(e) => {
                          setFieldValue("Ausstattung", e);
                        }}
                      />
                    </div>
                    <div className="reference">
                      <p>Ausschlusskriterien</p>
                      <Checkbox.Group
                        options={AusschlusskriterienList}
                        defaultValue={[]}
                        name="Ausschlusskriterien"
                        onChange={(e) => {
                          setFieldValue("Ausschlusskriterien", e);
                        }}
                      />
                    </div>

                    <div className="extras">
                      <p>Extras</p>
                      <Checkbox.Group
                        options={Extra_Options}
                        defaultValue={[]}
                        name="extras"
                        onChange={(e) => {
                          setFieldValue("extras", e);
                        }}
                      />
                    </div>

                    <div className="label-and-input">
                      <p>Überzeugen Sie potentielle Eigentümer von sich</p>
                      <Input
                        style={{ width: "30%" }}
                        placeholder="Type here"
                        name="zuUberzeugen"
                        onChange={(selected) => {
                          setFieldValue("zuUberzeugen", selected.target.value);
                        }}
                      />
                    </div>
                  </Modal>
                  <Select
                    defaultValue="Miete"
                    style={{ width: "25%" }}
                    bordered={false}
                    name="propertyType"
                    onChange={(selected) => {
                      setFieldValue("propertyType", selected);
                      setFieldValue(
                        "accomodationType",
                        Property_Options[selected][0]["label"]
                      );
                    }}
                  >
                    {Property_Types?.map((Accomodation_Type) => (
                      <Option value={Accomodation_Type.key}>
                        {Accomodation_Type.label}
                      </Option>
                    ))}
                  </Select>
                  <div className="vertical-divider" />
                  <Select
                    defaultValue="Wohnung"
                    value={values.accomodationType}
                    style={{ width: "30%" }}
                    bordered={false}
                    name="accomodationType"
                    onChange={(selected) => {
                      setFieldValue("accomodationType", selected);
                    }}
                  >
                    {Property_Options[values.propertyType]?.map(
                      (Accomodation_Type) => (
                        <Option value={Accomodation_Type.key}>
                          {Accomodation_Type.label}
                        </Option>
                      )
                    )}
                  </Select>

                  <div className="vertical-divider" />
                  <div className="address">
                    <LocationSvg />
                    {/* <Input
                      defaultValue="13583 Berlin-Spandau"
                      bordered={false}
                      name="address"
                      onChange={(selected) => {
                        setFieldValue("address", selected.target.value);
                      }}
                    /> */}

                    <Select
                      className="custom-input"
                      showSearch
                      placeholder="Select a place"
                      optionFilterProp="children"
                      bordered={false}
                      open={showMenu}
                      name="address"
                      popupClassName="custom-menu"
                      value={values.address}
                      onSearch={(selected) => {
                        setSearchQuery(selected);
                      }}
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      dropdownRender={() => (
                        <Menu
                          className="custom-menu"
                          onSelect={(selected) => {
                            setFieldValue("address", selected.key);

                            setMenu(false);
                          }}
                        >
                          <>
                            {placesLoading ? (
                              <div className="spinner">
                                <Spin size="large" />
                              </div>
                            ) : places?.length ? (
                              places?.map((item) => {
                                return (
                                  <Menu.Item
                                    key={item.name}
                                    className="menu-item"
                                  >
                                    {item.name}
                                  </Menu.Item>
                                );
                              })
                            ) : (
                              <div className="spinner"> No Place Found</div>
                            )}
                          </>
                        </Menu>
                      )}
                    />
                  </div>
                  <div className="horizontal-seprator-line" />
                  <Select
                    defaultValue="Bis 700€"
                    style={{ width: "33%" }}
                    bordered={false}
                    name="price"
                    onChange={(selected) => {
                      setFieldValue("price", selected);
                    }}
                  >
                    {Price_Range?.map((Accomodation_Type) => (
                      <Option value={Accomodation_Type.key}>
                        {Accomodation_Type.label}
                      </Option>
                    ))}
                  </Select>
                  <div className="vertical-divider" />
                  <Select
                    defaultValue="ab 40 m²"
                    style={{ width: "28%" }}
                    bordered={false}
                    name="area"
                    onChange={(selected) => {
                      setFieldValue("area", selected);
                    }}
                  >
                    {Area_Range?.map((Accomodation_Type) => (
                      <Option value={Accomodation_Type.key}>
                        {Accomodation_Type.label}
                      </Option>
                    ))}
                  </Select>
                  <div className="vertical-divider" />
                  <Select
                    defaultValue="2 Zimmer"
                    style={{ width: "25%" }}
                    bordered={false}
                    name="rooms"
                    onChange={(selected) => {
                      setFieldValue("rooms", selected);
                    }}
                  >
                    {Rooms_Range?.map((Accomodation_Type) => (
                      <Option value={Accomodation_Type.key}>
                        {Accomodation_Type.label}
                      </Option>
                    ))}
                  </Select>

                  {/* <button type="submit">Submit</button> */}
                </Form>
              </div>
              <div className="register-button" onClick={showModal}>
                <h3 className="next-button">Suchauftrag starten</h3>
                <RightArrow />
              </div>
            </div>
          );
        }}
      </Formik>
      <h3 className="headings">Registrieren Sie sich kostenlos!</h3>
      <p className="moto-description">
        Immointerest arbeitet mit zahlreichen Immobilienexperten zusammen, um
        für Sie die richtige Immobilie zu finden
      </p>
    </div>
  );
};

export default Registeration;
