import React from "react";
import "./styles.css";
const Footer = () => {
  return (
    <div className="footer-section">
      <a
        className="link"
        href="https://www.pricehubble.com/de/products/property-advisor/"
        target="blank"
      >
        Immobilienbewertung
      </a>
      <a
        className="link"
        href="https://www.interhyp-partnerprogramm.de/html.cgi?filename=index.htm​"
        target="blank"
      >
        Baufinanzierung
      </a>
      <a
        className="link"
        href="https://www.pricehubble.com/de/products/property-advisor/"
        target="blank"
      >
        Verkaufen
      </a>
    </div>
  );
};

export default Footer;
