export const baseUrl = "https://dev.stimm.dev:3005";
export const Accomodation_Types = [
  { label: "Wohnung", key: "Wohnung" },
  { label: "Haus", key: "Haus" },
  { label: "Gewerbe", key: "Gewerbe" },
];
export const Property_Options = {
  Miete: [
    { label: "Wohnung", key: "Wohnung" },
    { label: "Haus", key: "Haus" },
    { label: "Wohnen auf Zeit", key: "Wohnen auf Zeit" },
    { label: "Wohngemeinschaft", key: "Wohngemeinschaft" },
    { label: "Gewerbeimmobilien", key: "Gewerbeimmobilien" },
    { label: "Musterhausparks", key: "Garage/Stellplatz" },
    { label: "Grundstück", key: "Grundstück" },
    { label: "Neubauprojekt", key: "Neubauprojekt" },
    { label: "Sonstiges", key: "Sonstiges" },
  ],
  Kaufen: [
    { label: "Wohnung", key: "Wohnung" },
    { label: "Haus", key: "Haus" },
    { label: "Wohngemeinschaft", key: "Wohngemeinschaft" },
    { label: "Gewerbeimmobilien", key: "Gewerbeimmobilien" },
    { label: "Garage/Stellplatz", key: "Garage/Stellplatz" },
    { label: "Grundstück", key: "Grundstück" },
    { label: "Neubauprojekt", key: "Neubauprojekt" },
    { label: "Sonstiges", key: "Sonstiges" },
  ],
  Bauen: [
    { label: "Hausbau & Kataloge", key: "Hausbau & Kataloge" },
    { label: "Fertighäuser", key: "Fertighäuser" },
    { label: "Massivhäuser", key: "Massivhäuser" },
    { label: "Musterhäuser", key: "Musterhäuser" },
    { label: "Musterhausparks", key: "Musterhausparks" },
    { label: "Baufinanzierung", key: "Baufinanzierung" },
  ],
  Weiteres: [
    { label: "Renditeobjekte", key: "Renditeobjekte" },
    { label: "Neubauprojekte", key: "Neubauprojekte" },
    { label: "Auslandsimmobilien", key: "Auslandsimmobilien" },
    { label: "Zwangsversteigerung", key: "Zwangsversteigerung" },
  ],
};
export const Property_Types = [
  { label: "Miete", key: "Miete" },
  { label: "Kaufen", key: "Kaufen" },
  { label: "Bauen", key: "Bauen" },
  { label: "Weiteres", key: "Weiteres" },
];
export const Price_Range = [
  { label: "beliebiger Mietpreis", key: "beliebiger Mietpreis" },
  { label: "Bis 400€", key: "Bis 400€" },
  { label: "bis 500 €", key: "bis 500 €" },
  { label: "Bis 700€", key: "Bis 700€" },
  { label: "bis 900 €", key: "bis 900 €" },
  { label: "Bis 1000€", key: "Bis 1000€" },
  { label: " bis 1.200 €", key: " bis 1.200 €" },
  { label: "bis 1.500 €", key: "bis 1.500 €" },
  { label: "bis 1.800 €", key: "bis 1.800 €" },
];
export const Area_Range = [
  { label: "beliebige Wohnfläche", key: "beliebige Wohnfläche" },
  { label: "ab 40 m²", key: "ab 40 m²" },
  { label: "ab 50 m²", key: "ab 50 m²" },
  { label: "ab 60 m²", key: "ab 60 m²" },
  { label: "ab 70 m²", key: "ab 70 m²" },
  { label: "ab 80 m²", key: "ab 80 m²" },
  { label: "ab 90 m²", key: "ab 90 m²" },
  { label: "ab 100 m²", key: "ab 100 m²" },
  { label: "ab 120 m²", key: "ab 120 m²" },
];
export const Rooms_Range = [
  { label: "beliebige Zimmer", key: "beliebige Zimmer" },
  { label: "1 Zimmer", key: "1 Zimmer" },
  { label: "2 Zimmer", key: "2 Zimmer" },
  { label: "3 Zimmer", key: "3 Zimmer" },
  { label: "4 Zimmer", key: "4 Zimmer" },
  { label: "5+ Zimmer", key: "5+ Zimmer" },
];
export const Gewerbe = [
  { label: "Büro", key: "Büro" },
  { label: "Lagerräume-/hallen", key: "Lagerräume-/hallen" },
  { label: "Werkstätten", key: "Werkstätten" },
];
export const Eigentumswohnung_Types = [
  { label: "Obergeschoss", key: "Obergeschoss" },
  { label: "Erdgeschoss", key: "Erdgeschoss" },
  { label: "Dachgeschoss", key: "Dachgeschoss" },
];
export const Reference = [
  "Sonstiges",
  "Empfehlung",
  "Internet",
  "Flyer",
  "Zeitung/Wochenblatt",
];
export const IchWohneBisherList = ["Sonstiges", "zur Miete", "im Eigentum"];
export const AusstattungList = [
  "Bad mit Fenster",
  "Einbauküche",
  "Balkon oder Terrasse",
];
export const AusschlusskriterienList = [
  "Kein Erdgeschoss",
  "Kein Dachgeschoss",
  "Keine Neubauprojekte",
];
export const WohnungstypList = [
  "Terrassenwohnungen",
  "Maisonettes",
  "Rohdachböden",
  "Penthouse-Wohnungen",
  "Lofts",
  "Etagenwohnungen",
  "Attikawohnungen",
  "Apartments",
];
export const propertySearchingTime = [
  "gerade angefangen",
  "länger als 1 Jahr",
  "bis zu 1 Jahr",
  "3 Monate",
  "1 Monat",
];
export const Extra_Options = ["barrierefrei", "seniorengerecht"];
export const Haus_Types = [
  { label: "Einfamilienhaus", key: "Einfamilienhaus" },
  { label: "Zweifamilienhaus", key: "Zweifamilienhaus" },
  { label: "Doppelhaushälfte", key: "Doppelhaushälfte" },
  { label: "Reihenhaus", key: "Reihenhaus" },
  { label: "Mehrfamilienhaus", key: "Mehrfamilienhaus" },
  { label: "Zwangsversteigerungen ", key: "Zwangsversteigerungen " },
  { label: "Büros/Praxen", key: "Büros/Praxen" },
  { label: "Hallen", key: "Hallen" },
  { label: "Gastronomie/ Hotel", key: "Gastronomie/ Hotel" },
  { label: "Garagen / Stellplätze", key: "Garagen / Stellplätze" },
  { label: "Anlageobjekte", key: "Anlageobjekte" },
  {
    label: "Dächer zur Pacht für Solaranlagen",
    key: "Dächer zur Pacht für Solaranlagen",
  },
];
export const Wohnung_Types = [{ label: "Dachgeschoss", key: "Dachgeschoss" }];
export const GrundDerNutzung_Types = [
  { label: "Kapitalanlage", key: "Kapitalanlage" },
  { label: "Eigennutzung", key: "Eigennutzung" },
];
export const Grundstück_Types = [
  { area: "400qm", type: "Sonstige", key: "400qm|Sonstige" },
  { area: "400qm", type: "Bauplatz Wohnen", key: "400qm|Bauplatz Wohnen" },
  { area: "400qm", type: "Bauplatz Gewerbe", key: "400qm|Bauplatz Gewerbe" },
];

export const Anbieten_Types = [
  { label: "Immobilienpreise", key: "Immobilienpreise" },
  { label: "Ratgeber & Leistungen", key: "Ratgeber & Leistungen" },
  { label: "Für Immobilien-Profis", key: "Für Immobilien-Profis" },
  { label: "Für private Anbieter", key: "Für private Anbieter" },
];
export const Baujahr_Types = [
  { label: "1 beliebig", key: "1 beliebig" },
  { label: "2 beliebig", key: "2 beliebig" },
  { label: "3 beliebig", key: "3 beliebig" },
  { label: "4 beliebig", key: "4 beliebig" },
];
export const Wohnberechtigungsschein_Types = [
  { label: "alle Angebote", key: "alle Angebote" },
  { label: "nicht erforderlich", key: "nicht erforderlich" },
  { label: "erforderlich", key: "erforderlich" },
];
export const Haustiere_Types = [
  { label: "erlaubt", key: "erlaubt" },
  { label: "alle Angebote", key: "alle Angebote" },
];
export const Umkreis_Types = [
  { label: "kein Umkreis", key: "kein Umkreis" },
  { label: "1 km", key: "1 km" },
  { label: "50 km", key: "50 km" },
  { label: "30 km", key: "30 km" },
  { label: "20 km", key: "20 km" },
  { label: "10 km", key: "10 km" },
  { label: "5 km km", key: "5 km km" },
  { label: "3 km", key: "3 km" },
  { label: "2 km", key: "2 km" },
];
export const Anbieten_Options = {
  Immobilienpreise: [
    { label: "Price Map", key: "Price Map" },
    { label: "Preisstatistik", key: "Preisstatistik" },
    { label: "Kostenlose Wertermittlung", key: "Kostenlose Wertermittlung" },
  ],
  "Ratgeber & Leistungen": [
    { label: "Immobilien-Ratgeber", key: "Immobilien-Ratgeber" },
    { label: "Energieausweis", key: "Energieausweis" },
  ],
  "Für Immobilien-Profis": [
    { label: "Tipps für Makler", key: "Tipps für Makler" },
    { label: "Services für Profis", key: "Services für Profis" },
    { label: "erfolgreich vermarkten", key: "erfolgreich vermarkten" },
    { label: "Produkte für Profis", key: "Produkte für Profis" },
  ],
  "Für private Anbieter": [
    { label: "Makler finden", key: "Makler finden" },
    { label: "Eigentümer-Welt", key: "Eigentümer-Welt" },
    { label: "Immobilienbewertung", key: "Immobilienbewertung" },
    { label: "Haus verkaufen", key: "Haus verkaufen" },
    { label: "Wohnung vermieten", key: "Wohnung vermieten" },
    { label: "Kostenlos vermieten", key: "Kostenlos vermieten" },
    { label: "Anzeige aufgeben", key: "Anzeige aufgeben" },
  ],
};
